import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';

const Home = (props) => {
  return (
    <>
      <Hero className="illustration-section-01" id={props.location.hash} />
      <FeaturesTiles id='#aboutSection' />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      {/* <Testimonial topDivider /> */}
      <div className='container' >
        <iframe src="https://www.google.com/maps/d/embed?mid=1oMx66q6-FWiUJvY3fllsnHoMQbrEEA4&ehbc=2E312F" width="100%" height="480"></iframe>
      </div>
      <Cta split />
    </>
  );
}

export default Home;