import React, { useEffect } from 'react';

const DownloadPage = () => {
    const handleDownload = () => {
        const filePath = `/banjara_ride_app.apk`;
        const link = document.createElement('a');
        link.href = filePath;
        link.setAttribute('download', 'banjara_ride_app.apk');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        handleDownload();
    }, []);

    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70vh', width: '100vw' }}>
            <div style={{ textAlign: 'center', lineHeight: '44px' }}>
                Click here if download doesn't start{' '}
                <button style={{ padding: '10px', color: 'ffffff', borderRadius: '12px', margin: '0px 15px' }} onClick={handleDownload}>
                    Download
                </button>
            </div>
        </div>
    );
};

export default DownloadPage;
